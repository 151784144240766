import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Lightbox from "react-image-lightbox"
import { Box, Button, Grid, Paper, Typography } from "@material-ui/core"
import Layout from "../components/layout/main"
import Lead from "../components/global/text/lead"
import FiltreGaleri from "../components/global/carousel/filtregaleri"
import FiltreKullanimAlani from "../components/global/filtre/kullanimalani"

import LightboxImageWrapper from "../components/global/lightbox/lightbox-image-wrapper"
import Seo from "../components/global/seo/seo.jsx"
const PaslanmazTurbinGaz = () => {
  const data = useStaticQuery(graphql`
    query {
      resim1: file(relativePath: { eq: "filtre-galeri/turbin-gaz-teli4.jpg" }) {
        childImageSharp {
          thumb: fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          full: fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      resim2: file(relativePath: { eq: "filtre-galeri/turbin-gaz-teli.jpg" }) {
        childImageSharp {
          thumb: fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          full: fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      resim3: file(relativePath: { eq: "filtre-galeri/turbin-gaz-teli1.jpg" }) {
        childImageSharp {
          thumb: fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          full: fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      resim4: file(relativePath: { eq: "filtre-galeri/turbin-gaz-teli2.jpg" }) {
        childImageSharp {
          thumb: fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          full: fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      resim5: file(relativePath: { eq: "filtre-galeri/turbin-gaz-teli3.jpg" }) {
        childImageSharp {
          thumb: fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          full: fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const thumbArray = [
    { imgName: "resim2", imgAlt: "Paslanmaz Türbin Teli - Gaz Teli" },
    { imgName: "resim3", imgAlt: "Paslanmaz Türbin Teli - Gaz Teli" },
    { imgName: "resim4", imgAlt: "Paslanmaz Türbin Teli - Gaz Teli" },
    { imgName: "resim5", imgAlt: "Paslanmaz Türbin Teli - Gaz Teli" },
  ]
  const fullImgArray = [
    data.resim1.childImageSharp.full.src,
    data.resim2.childImageSharp.full.src,
    data.resim3.childImageSharp.full.src,
    data.resim4.childImageSharp.full.src,
    data.resim5.childImageSharp.full.src,
  ]
  const [isOpen, toggleOpenLightbox] = React.useState(false)
  const toggleLightbox = opened => {
    toggleOpenLightbox(opened)
  }
  const [imgIndex, setIndex] = React.useState(0)
  const setImgIndex = imageIndex => {
    setIndex(imageIndex)
  }
  const openLightBox = iIndex => {
    toggleLightbox(true)
    setImgIndex(iIndex)
  }
  const setPrevImgIndex = () => {
    const newIndex = (imgIndex + fullImgArray.length - 1) % fullImgArray.length
    setImgIndex(newIndex)
  }
  const setNextImgIndex = () => {
    const newIndex = (imgIndex + 1) % fullImgArray.length
    setImgIndex(newIndex)
  }
  return (
    <Layout>
      <Seo
        title="Paslanmaz Türbin Gaz Telleri - Paslanmaz Filtre Erez Kaynak " 
        description="Firmamız 10cm, 15cm ve 50cm eninde olarak galvaniz telden, bakır telden, 304 kalite paslanmaz ve 316 kalite paslanmaz telden türbin gaz telleri imalatı yapmaktadır."
        keywords="paslanmaz türbin teli,türbin gaz teli imalatı,türbin teli imalatı,gaz teli imalatı,türbin teli fiyatı,gaz teli fiyatı,türbin gaz teli fiyatı,türbin gaz teli fiyatları,gaz telleri,türbin gaz telleri,paslanmaz gaz teli,türbin telleri,10cm türbin teli,20cm türbin teli,30cm türbin teli,40cm türbin teli,bakır türbin teli,50cm türbin teli,türbin telleri fiyatları,gaz telleri fiyatları,paslanmaz türbin teli fiyatı"
        url="paslanmaz-turbin-gaz-telleri"
      />

      <Box my={3}>
        <Paper elevation={4}>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box px={3} pt={3}>
                <Box textAlign="center">
                  <Typography variant="h4" component="h1">
                    <Box fontWeight={500}>Paslanmaz Türbin Gaz Telleri</Box>
                  </Typography>
                </Box>
                <Lead>
                  Firmamız 10cm, 15cm ve 50cm eninde olarak galvaniz telden,
                  bakır telden, 304 kalite paslanmaz ve 316 kalite paslanmaz
                  telden <span>türbin gaz telleri</span> imalatı yapmaktadır.
                </Lead>

                <Lead>
                  Detaylı bilgi ya da fiyat teklifi almak için bizi arayınız.
                </Lead>
                <Box textAlign="center" pt={3}>
                  <Link to="/iletisim" style={{ textDecoration: "none" }}>
                    <Button variant="outlined" color="secondary">
                      İLETİŞİM BİLGİLERİ
                    </Button>
                  </Link>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box px={3} py={3}>
                <div onClick={() => openLightBox(0)}>
                  <LightboxImageWrapper
                    fluid={data.resim1.childImageSharp.thumb}
                    imgAlt="Paslanmaz Filtre ve Elek Mesh Teller"
                    imgClass=""
                  />
                </div>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Box py={3}>
        <Paper elevation={4}>
          <Box p={3}>
            <FiltreKullanimAlani
              align="center"
              title="Paslanmaz Türbin Teli - Gaz Teli Kullanım Alanları"
            />
          </Box>
        </Paper>
      </Box>
      <Box py={3}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={3}
        >
          {thumbArray.map((resim, index) => (
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <div onClick={() => openLightBox(index + 1)}>
                <LightboxImageWrapper
                  fluid={data[resim.imgName].childImageSharp.thumb}
                  imgClass=""
                  imgAlt={resim.imgAlt}
                />
              </div>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box my={3}>
        <FiltreGaleri />
      </Box>

      {isOpen && (
        <Lightbox
          mainSrc={fullImgArray[imgIndex]}
          nextSrc={fullImgArray[(imgIndex + 1) % fullImgArray.length]}
          prevSrc={
            fullImgArray[
              (imgIndex + fullImgArray.length - 1) % fullImgArray.length
            ]
          }
          onCloseRequest={() => toggleLightbox(false)}
          onMovePrevRequest={setPrevImgIndex}
          onMoveNextRequest={setNextImgIndex}
        />
      )}
    </Layout>
  )
}
export default PaslanmazTurbinGaz
